(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/progress-bar/assets/javascripts/progress-bar.js') >= 0) return;  svs.modules.push('/components/lb-ui/progress-bar/assets/javascripts/progress-bar.js');
"use strict";

const {
  useRef,
  useEffect
} = React;
const ProgressBar = _ref => {
  let {
    minValue = 0,
    maxValue = 100,
    currValue = 0,
    className = ''
  } = _ref;
  const totalValue = maxValue - minValue;
  let percentProgress = 0;
  if (currValue >= minValue && currValue <= maxValue) {
    percentProgress = currValue / totalValue * 100;
  }
  const percentBar = useRef(null);
  useEffect(() => {
    if (percentBar.current) {
      percentBar.current.style.width = percentProgress.toString().concat('%');
    }
  }, [percentBar, percentProgress]);
  const classnames = ['pg-progress-bar'];
  if (className) {
    classnames.push(className);
  }
  return React.createElement("div", {
    className: classnames.join(' ')
  }, React.createElement("div", {
    className: "pg-progress-bar-percent",
    ref: percentBar
  }));
};
setGlobal('svs.components.lbUi.progressBar.ProgressBar', ProgressBar);

 })(window);